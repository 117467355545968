import { render, staticRenderFns } from "./TokenShowComponent.vue?vue&type=template&id=4a409844&scoped=true&"
import script from "./TokenShowComponent.vue?vue&type=script&lang=js&"
export * from "./TokenShowComponent.vue?vue&type=script&lang=js&"
import style0 from "./TokenShowComponent.vue?vue&type=style&index=0&id=4a409844&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a409844",
  null
  
)

export default component.exports