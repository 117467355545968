<template>
  <v-dialog
      v-model="dialog"
      max-width="1080"
      persistent
      @click:outside="closeDialog()"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on:btn }">
          <v-btn text icon small v-on="btn" :loading="loadingLots">
            <template v-slot:loader>
              <v-progress-circular dark size="12" width="2" indeterminate />
            </template>
            <v-icon small
                    class="zoom__btn"
                    @click="showDialog()"
            >
              mdi-eye-outline
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('general.titles.details') }}
        </span>
      </v-tooltip>
    </template>
    <v-card v-if="selectToken">
      <v-overlay :opacity="0.9" :value="launchLoadingMetamask" absolute color="white">
        <p class="text--disabled text-end ma-0 pa-0 code-metamask">{{ $t('tokens.titles.waitingFor') }}</p>
        <v-img :src="require('@/assets/img/metamask-logo.svg')" contain/>
        <v-progress-linear class="mt-1" color="#f5841f" indeterminate/>
      </v-overlay>
      <v-card-title class="text-h5 white--text secondary text-uppercase">
        {{ $t('general.titles.details') }}
      </v-card-title>
      <div class="ma-8">
        <div class="d-flex justify-space-between align-center">
          <div class="ma-1">
            <span class="text-h6">{{ $t('tokens.titles.tokenName') }}: {{ selectToken.product }}</span>
          </div>
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex justify-start align-center align-content-center ma-1">
              <p class="ma-0 px-2 py-1 token-name"
                 :style="`width: ${selectToken.product ? selectToken.product.length*10 : '' }`"
              >
              {{ $t('inventory.titles.totalEmitted') }}
              </p>
              <div>
                <p class="ma-0 px-2 py-1 token-address__default">
                  {{ selectToken.tokenized }}
                </p>
              </div>
            </div>
            <div class="d-flex justify-start align-center align-content-center">
              <p class="ma-0 px-2 py-1 token-name"
                 :style="`width: ${selectToken.product ? selectToken.product.length*10 : '' }`"
              >
                {{ $t('tokens.titles.availableAmount') }}
              </p>
              <div>
                <p class="ma-0 px-2 py-1 token-address__available">
                  {{ selectToken.virtualStock }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-card-text>
        <v-data-table
          :footer-props="{
            ...rowsPerPageItemsOptions,
            itemsPerPageText: $t('general.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
          }"
          :headers="headers"
          :items="lots"
          :loading="loading"
          :no-data-text="$t('general.ui.not_data_found')"
          :no-results-text="$t('general.ui.not_search_found')"
          :search="search"
          height="200px"
        >
          <template v-slot:top>
            <v-toolbar color="white" flat>
              <v-row class="d-flex justify-space-between align-baseline pb-10">
                <v-col class="pl-0" cols="6" lg="4" md="4" sm="6" xl="4">
                  <v-text-field
                      v-model="search"
                      :clearable="true"
                      :label="$t('general.titles.filter')"
                      append-icon="mdi-magnify"
                      color="secondary"
                      dense
                      filled
                      hide-details
                      onkeypress="return (event.charCode != 34)"
                      rounded
                      single-line
                      item-key="id"
                  />
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.locations="{ item }">
            {{ item.locations.primary.name }} | {{ item.locations.secondary.name }}
          </template>
          <template v-slot:item.available="{ item }">
            <v-chip small>
              {{ item.available }}
            </v-chip>
          </template>
          <template v-slot:item.tokenized="{ item }">
            <span class="token-emitted">
              {{ item.tokenized }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn
            :disabled="loading"
            class="btn-bg-red"
            text
            @click="closeDialog()"
        >
          {{ $t('general.buttons.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import i18n from "@/plugins/i18n";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "TokenShowComponent",

  props: {
    token: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      dialog: false,
      loading: false,
      loadingLots: false,
      search: null,
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [5, 10],
      },
      launchLoadingMetamask: false,
      selectToken: null
    }
  },

  computed: {
    ...mapGetters({
      getTotalMinted: 'web3/GET_TOTAL_MINTED',
      factory: 'web3/GET_FACTORY',
      validateMoreThan: 'general/validateMoreThan'
    }),
    lots: {
      get() {
        return this.$store.getters['web3/GET_LOTS']
      },
      set(val) {
        this.$store.commit('web3/SET_LOTS', val)
      }
    },
    headers() {
      return [{
        text: i18n.t('inventory.titles.lots'), value: "lot", val: "lot"
      },{
        text: i18n.t('inventory.titles.unit'), value: "measurementUnit", val: "measurementUnit"
      },{
        text: i18n.t('inventory.titles.locations'), value: "locations", val: "locations"
      },{
        text: i18n.t('inventory.titles.emitted'), value: "tokenized", val: "tokenized"
      },{
        text: i18n.t('inventory.titles.avilable'), value: "available", val: "available"
      }]
    },
    isValid() {
      let count = 0
      _.forEach(this.valid, (v) => {
        if(!v) count ++
      })

      return (count > 0)
    },
    moreThan() {
      let total = 0
      _.forEach(this.amounts, (a) => {
        if(a)
          total += parseInt(a)
      })

      return total > 0
    }
  },

  methods: {
    ...mapActions({
      fetchLotsNFTs: 'web3/FETCH_LOTS_NFTs'
    }),
    async showDialog() {
      this.loadingLots = true
      this.refactorToken()

      await this.fetchLotsNFTs({
        action: "SHOW",
        contractID: this.token.id,
        product: this.token.product,
      }).finally(() => {
        this.loadingLots = false
        this.dialog = true
      })
    },
    refactorToken() {
      return this.selectToken = _.cloneDeep(this.token)
    },
    closeDialog() {
      this.loading = false
      this.selectToken = null
      this.amounts = []
      this.dialog = false
    },
  }
}
</script>

<style scoped>
.v-icon {
  font-size: 18px !important;
}
.token-name {
  background: rgba(0, 0, 0, .07);
  color: #1a1a1a;
  border-radius: .3em 0 0 .3em;
  font-size: 12px;
  font-weight: 500;
}

.token-emitted {
  padding: 6px 12px;
  border-radius: .3em;
  font-size: 12px;
  font-weight: 500;
  background: #f3f3f3;
  color: #1a1a1a;
}

.token-address__default {
  border-radius: 0 .3em .3em 0;
  font-size: 12px;
  font-weight: 500;
  background: #f3f3f3;
  color: #1a1a1a;
}

.token-address__available {
  border-radius: 0 .3em .3em 0;
  font-size: 12px;
  font-weight: 500;
  background: rgb(3 172 140 / 7%);
  color: rgb(15 155 128 / 70%);
}

.v-chip {
  border-radius: .3em !important;
  background-color: rgb(117 227 81 / 24%) !important;
  color: seagreen !important;
  font-weight: 800;
}
</style>