<template>
  <v-dialog
      v-model="dialog"
      max-width="1080"
      persistent
      @click:outside="closeDialog()"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on:btn }">
          <v-btn text icon small v-on="btn" :loading="loadingLots">
            <template v-slot:loader>
              <v-progress-circular dark size="12" width="2" indeterminate />
            </template>
            <v-icon small
                    class="zoom__btn"
                    @click="showDialog()"
            >
              mdi-cube-send
            </v-icon>
          </v-btn>
        </template>
        <span>
              {{ $t('tokens.titles.emitToken') }}
            </span>
      </v-tooltip>
    </template>
    <v-card v-if="selectToken">
      <v-overlay :opacity="0.9" :value="launchLoadingMetamask" absolute color="white">
        <p class="text--disabled text-end ma-0 pa-0 code-metamask">{{ $t('tokens.titles.waitingFor') }}</p>
        <v-img :src="require('@/assets/img/metamask-logo.svg')" contain/>
        <v-progress-linear class="mt-1" color="#f5841f" indeterminate/>
      </v-overlay>
      <v-card-title class="text-h5 white--text secondary text-uppercase">
        {{ $t('tokens.titles.emitToken') }} Tokens
      </v-card-title>
      <div class="ma-8">
        <div class="d-flex justify-space-between align-center">
          <div class="ma-1">
            <span class="text-h6">{{ $t('tokens.titles.tokenName') }}: {{ selectToken.product }}</span>
          </div>
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex justify-start align-center align-content-center ma-1">
              <p class="ma-0 px-2 py-1 token-name"
                 :style="`width: ${selectToken.product ? selectToken.product.length*10 : '' }`"
              >
                {{ $t('inventory.titles.totalEmitted') }}
              </p>
              <div>
                <p class="ma-0 px-2 py-1 token-address__default">
                  {{ selectToken.tokenized }}
                </p>
              </div>
            </div>
            <div class="d-flex justify-start align-center align-content-center">
              <p class="ma-0 px-2 py-1 token-name"
                 :style="`width: ${selectToken.product ? selectToken.product.length*10 : '' }`"
              >
                {{ $t('tokens.titles.availableAmount') }}
              </p>
              <div>
                <p class="ma-0 px-2 py-1 token-address__available">
                  {{ selectToken.virtualStock }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageText: $t('general.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
          }"
          :headers="headers"
          :items="lots"
          :loading="loading"
          :no-data-text="$t('general.ui.not_data_found')"
          :no-results-text="$t('general.ui.not_search_found')"
          :search="search"
          show-select
          v-model="selected"
          @item-selected="isSelected($event)"
          @toggle-select-all="selectAll($event)"
          height="200px"
        >
          <template v-slot:top>
            <v-toolbar color="white" flat>
              <v-row class="d-flex justify-space-between align-baseline pb-10">
                <v-col class="pl-0" cols="6" lg="4" md="4" sm="6" xl="4">
                  <v-text-field
                      v-model="search"
                      :clearable="true"
                      :label="$t('general.titles.filter')"
                      append-icon="mdi-magnify"
                      color="secondary"
                      dense
                      filled
                      hide-details
                      onkeypress="return (event.charCode != 34)"
                      rounded
                      single-line
                      item-key="id"
                  />
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4" class="d-flex justify-end">
                  <strong>{{ $t('inventory.titles.totalToEmit') }}
                    <v-chip small class="default">
                      {{ sum() }}
                    </v-chip>
                  </strong>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.locations="{ item }">
            {{ item.locations.primary.name }} | {{ item.locations.secondary.name }}
          </template>
          <template v-slot:item.available="{ item }">
            <v-chip small :class="amount(item) >= 0 ? 'success' : 'error'">
              {{ amount(item) }}
            </v-chip>
          </template>
          <template v-slot:item.amount="{ item }">
            <v-form ref="formAmount" v-model="valid[item.id]" v-if="item.isSelected">
              <v-text-field 
                class="ma-1"
                :disabled="loading"
                :placeholder="$t('tokens.fields.quantity')"
                :hide-details="!(amounts[item.id] > item.available)"
                :rules="validateMoreThan(0, item.available)"
                :min="0"
                @input="isEmpty(amounts[item.id], item.id)"
                v-model="amounts[item.id]"
                background-color="#EAEAEA80"
                color="secondary"
                flat
                small
                solo
                dense
                type="number"
              />
            </v-form>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn
            :disabled="loading"
            class="btn-bg-red"
            text
            @click="closeDialog()"
        >
          {{ $t('general.buttons.cancel') }}
        </v-btn>
        <ConfirmDialog :valid="!(lots.length > 0) || isValid || !moreThan"
                       :loading="loading"
                       :btn-title="$t('tokens.titles.emitToken')"
                       :is-tooltip="false"
                       :is-btn="true"
                       :is-icon="false"
                       styles="btn-bg-green"
                       action="toEmit"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import i18n from "@/plugins/i18n";
import {mapActions, mapGetters} from "vuex";
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  name: "TokenEmitComponent",
  components: { ConfirmDialog },
  props: {
    token: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      dialog: false,
      loading: false,
      loadingLots: false,
      search: null,
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [5, 10],
      },
      launchLoadingMetamask: false,
      selectToken: null,
      valid: {},
      selected: [],
      amounts: []
    }
  },

  mounted() {
    this.$root.$on("toEmit", async () => {
      await this.toEmit();
    });
  },

  computed: {
    ...mapGetters({
      getTotalMinted: 'web3/GET_TOTAL_MINTED',
      factory: 'web3/GET_FACTORY',
      validateMoreThan: 'general/validateMoreThan'
    }),
    lots: {
      get() {
        return this.$store.getters['web3/GET_LOTS']
      },
      set(val) {
        this.$store.commit('web3/SET_LOTS', val)
      }
    },
    headers() {
      return [{
        text: i18n.t('inventory.titles.lots'), value: "lot", val: "lot"
      },{
        text: i18n.t('inventory.titles.unit'), value: "measurementUnit", val: "measurementUnit"
      },{
        text: i18n.t('inventory.titles.locations'), value: "locations", val: "locations"
      },{
        text: i18n.t('inventory.titles.avilable'), value: "available", val: "available"
      },{
        text: i18n.t('inventory.titles.amount'), value: "amount", val: "amount", width: 200, align: "center"
      }]
    },
    amount() {
      return (item) => {
        let s = item.isSelected
        let a = item.available
        let i = this.amounts[item.id]

        if(s) {
          if(!i) return a
          else return (a-i)
        } {
          return item.available
        }
      }
    },
    isValid() {
      let count = 0
      _.forEach(this.valid, (v) => {
        if(!v) count ++
      })

      return (count > 0)
    },
    moreThan() {
      let total = 0
      _.forEach(this.amounts, (a) => {
        if(a)
          total += parseInt(a)
      })

      return total > 0
    }
  },

  methods: {
    ...mapActions({
      fetchStock: 'web3/FETCH_STOCK',
      generateReference: 'web3/GENERATE_REFERENCE',
      fetchLotsNFTs: 'web3/FETCH_LOTS_NFTs',
      emitTokens: 'web3/EMIT_TOKENS'
    }),
    sum(value) {
      let amount = 0
      if(value) {
        _.forEach(value, (v) => {
          if(v) amount += parseInt(v)
        })
      } else {
        _.forEach(this.amounts, (a) => {
          if(a) amount += parseInt(a)
        })
      }

      return amount
    },
    isEmpty(value, index) {
      if(value === '' || value === null) {
        this.$set(this.amounts, index, 0)
      }
    },
    isSelected(event) {
      if(event.value) {
        this.amounts[event.item.id] = 0
      } else {
        delete this.amounts[event.item.id]
      }

      _.find(this.lots, { id: event.item.id }).isSelected = event.value

      setTimeout(() => {
        this.amounts = this.amounts.map(a => parseInt(a))
        this.sum(this.amounts)
      }, 500)
    },
    selectAll(event) {
      if(event.value) {
        setTimeout(() => {
          _.forEach(this.selected, (s) => {
            if(!s.isSelected) {
              this.$set(s, 'isSelected', true)
              this.amounts[s.id] = 0
            }
          })
          this.sum(this.amounts)
        }, 500)
      } else {
        _.forEach(this.selected, (s) => {
          this.$set(s, 'isSelected', false)
          delete this.amounts[s.id]
        })
        this.sum(this.amounts)
      }
    },
    async toEmit() {
      this.loading = true

      let _references = []
      let _amounts = _.filter(this.amounts, a => { return !!a })
          _amounts = _amounts.map(a => parseInt(a))

      await this.generateReference(this.selected.length).then(res => {
        _references = res
      })

      _.forEach(this.selected, async (s, index) => {
        if(s) {
          if(!(s.reference && s.reference !== '')) {
            s['reference'] = _references[index]
          }
          s['amount'] = _amounts[index]
        }
      })

      setTimeout(async () => {
        const data = {
          action: "EMIT",
          contractId: this.token.id,
          contractAddress: this.token.contractAddress,
          product: this.token.product,
          lots: this.selected
        }

        const _lots = this.selected.map(l => l.lot)
        _references = this.selected.map(l => parseInt(l.reference))
      
        await this.emitTokens({ 
          data, 
          references: _references, 
          amounts: _amounts, 
          description: _lots
        }).finally(() => {
          this.closeDialog()
        })
      }, 2000)
    },
    async showDialog() {
      this.loadingLots = true
      this.refactorToken()

      await this.fetchLotsNFTs({
        action: "EMIT",
        contractID: this.token.id,
        product: this.token.product,
      }).finally(() => {
        this.loadingLots = false
        this.dialog = true
      })
    },
    refactorToken() {
      return this.selectToken = _.cloneDeep(this.token)
    },
    clearForm() {
      this.amounts = []
      if(this.selected && this.selected.length > 0) {
        _.forEach(this.selected, (l) => {
          l.isSelected = false
        })
        this.$refs.formAmount.resetValidation()
        this.selected = []
      }
      _.forEach(this.lots, (l) => {
        l.isSelected = false
      })
    },
    closeDialog() {
      this.loading = false
      this.selectToken = null
      this.dialog = false
      this.clearForm()
    },
  },

  destroyed() {
    this.$root.$off('toEmit')
  }
}
</script>

<style scoped>
.token-name {
  background: rgba(0, 0, 0, .07);
  color: #1a1a1a;
  border-radius: .3em 0 0 .3em;
  font-size: 12px;
  font-weight: 500;
}

.token-address__default {
  border-radius: 0 .3em .3em 0;
  font-size: 12px;
  font-weight: 500;
  background: #f3f3f3;
  color: #1a1a1a;
}

.token-address__available {
  border-radius: 0 .3em .3em 0;
  font-size: 12px;
  font-weight: 500;
  background: rgb(3 172 140 / 7%);
  color: rgb(15 155 128 / 70%);
}

.v-chip.success {
  border-radius: .3em !important;
  background-color: rgb(117 227 81 / 24%) !important;
  color: seagreen !important;
  font-weight: 800;
}

.v-chip.error {
  border-radius: 0.3em !important;
  background-color: rgb(231 11 11 / 15%) !important;
  color: rgb(245 30 30) !important;
  font-weight: 600;
}

.v-chip.default {
  border-radius: 0.3em !important;
  font-weight: 600;
}
</style>